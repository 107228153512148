.react-calendar {
    width: unset;
}
.react-calendar__navigation {
    justify-content: end;
    position: relative;
    /* padding-top: 1rem; */
    margin-bottom: 0;
}

.react-calendar__navigation .react-calendar__navigation__prev2-button, .react-calendar__navigation .react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation .react-calendar__navigation__label {
    position: absolute;
    left: 0;
    height: 100%;
    padding-left: 1.5rem;
}

.react-calendar__navigation .react-calendar__navigation__arrow {
    font-size: 2rem;
}

.react-calendar__viewContainer {
    padding-bottom: 10px;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: unset;
}

.react-calendar__viewContainer .react-calendar__month-view abbr[title] {
    text-transform: capitalize;
    text-decoration: none;
    color: #969696;
}

.react-calendar__month-view__days, .react-calendar__month-view__weekdays {
    display: grid !important;
    grid-template-columns: repeat(7, 50px);
    justify-content: space-around;
    font-family: "Roboto"
}

.react-calendar .react-calendar__viewContainer button {
    height: 50px;
    font-size: 0.9rem;
}

.react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    border-radius: 50%;
    background-color: #F5BE17;
    color: black;
}

.react-calendar button:hover {
    border-radius: 50%;
    background-color: #E5E8EE;
    color: black;
}

.react-calendar__tile:disabled {
    background-color: unset;
}

.react-calendar__month-view__days__day--weekend:disabled {
    opacity: 0.5;
}

.react-calendar__tile--now {
    background-color: #E5E8EE;
    border-radius: 50%;
}

.react-calendar {
    font-family: 'Poppins', sans-serif;
    border-radius: 0.3rem !important;
    border: none;
}
@media only screen and (min-width: 768px) and (max-width: 992.99px){
    .react-calendar__month-view__days, .react-calendar__month-view__weekdays {
        display: grid !important;
        grid-template-columns: repeat(7, 35px);
        justify-content: space-around;
        font-family: "Roboto"
    }
}
@media only screen and (max-width: 576.99px){
    .react-calendar__month-view__days, .react-calendar__month-view__weekdays {
        display: grid !important;
        grid-template-columns: repeat(7, 25px);
        justify-content: space-around;
        font-family: "Roboto";
        grid-gap: 5px;
    }
    .react-calendar .react-calendar__viewContainer button {
        height: 25px;
        padding: 5px;
    }
}